import "./App.css";
import LandingPage from "./pages/landingpage";
import Privacy from "./pages/Privacy";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

function App() {
  return (
    <Router>
      <main>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy" component={Privacy} />
          <Redirect to="/" />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
