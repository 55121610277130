import React from "react";

import tab1 from "../images/tabella1.png";
import tab2 from "../images/tabella2.png";
import privacy from "../images/privacy-policy.png";

const Privacy = () => {
  return (
    <div className="card m-0 border-0">
      <h2
        className="text-center textAudio text-blu"
        style={{ fontWeight: 700 }}
      >
        INFORMATIVA SULLA PRIVACY
      </h2>
      <div className="text-center">
        {" "}
        <img
          src={privacy}
          alt="."
          style={{
            width: "60px",
            filter: "opacity(.5) drop-shadow(0 0 0 #737373)",
          }}
        />
      </div>
      <div className="col-lg-6 mx-auto text-secondary mb-3">
        INFORMATIVA ai sensi degli artt.13 e 14 Reg. UE 2016/679 (TRATTAMENTO
        DEI DATI PERSONALI)
        <br />
        In questa pagina si descrivono le modalità di gestione del sito in
        riferimento al trattamento dei dati personali degli utenti che lo
        consultano. L’informativa è resa solo per il presente sito e non anche
        per altri siti web eventualmente consultati dall’utente tramite link.
        <br />
        <br />
        IL “TITOLARE” DEL TRATTAMENTO
        <br />
        <br />
        A seguito della consultazione di questo sito possono essere trattati
        dati relativi a persone identificate o identificabili. Titolare del
        trattamento è FINCREA, Viale della Regione Siciliana Nord Ovest, 3414, 90145 Palermo (PA).
        <br />
        <br />
        TIPI DI DATI TRATTATI - Dati di navigazione
        <br />I sistemi informatici e le procedure software preposte al
        funzionamento di questo sito web acquisiscono, nel corso del loro
        normale esercizio, alcuni dati personali la cui trasmissione è implicita
        nell’uso dei protocolli di comunicazione di Internet. Si tratta di
        informazioni che non sono raccolte per essere associate a interessati
        identificati, ma che per loro stessa natura potrebbero, attraverso
        elaborazioni ed associazioni con dati detenuti da terzi, permettere di
        identificare gli utenti. In questa categoria di dati rientrano gli
        indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che
        si connettono al sito, gli indirizzi in notazione URI (Uniform Resource
        Identifier) delle risorse richieste, l’orario della richiesta, il metodo
        utilizzato nel sottoporre la richiesta al server, la dimensione del file
        ottenuto in risposta, il codice numerico indicante lo stato della
        risposta data dal server (buon fine, errore, ecc.) ed altri parametri
        relativi al sistema operativo e all’ambiente informatico dell’utente,
        Questi dati vengono utilizzati al solo fine di ricavare informazioni
        statistiche anonime sull’uso del sito e per controllarne il corretto
        funzionamento. I dati potrebbero essere utilizzati per l’accertamento di
        responsabilità solo in caso di ipotetici reati informatici ai danni del
        sito e solo in tal caso potranno attivarsi specifiche procedure volte
        alla identificazione dell’autore.
        <br />
        <br />
        MODALITA’ DEL TRATTAMENTO ed incaricati
        <br />
        I dati personali sono trattati con strumenti automatizzati per il tempo
        strettamente necessario a conseguire gli scopi per cui sono stati
        raccolti. I trattamenti ai servizi web di questo sito sono curati da
        personale di FINCREA nonchè da eventuali incaricati di occasionali
        operazioni di gestione tecnica e manutenzione del sito e dei relativi
        sistemi informatici. Specifiche misure di sicurezza sono osservate per
        prevenire la perdita dei dati, usi illeciti o non corretti ed accessi
        non autorizzati.
        <br />
        <br />
        Nessun dato derivante dal servizio web viene diffuso.
        <br />
        <br />
        I dati personali forniti dagli utenti che inoltrano richieste di invio
        di materiale informativo (newsletter, risposte a quesiti, ecc.) sono
        utilizzati al solo fine di eseguire il servizio o la prestazione
        richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal
        fine necessario.
        <br />
        <br />
         CONFERIMENTO DEI DATI
        <br /> A parte quanto specificato per i dati di navigazione, l’utente è
        libero di fornire i dati personali richiesti nel corso della navigazione
        per sollecitare l’invio di materiale informativo o di altre
        comunicazioni. Il loro mancato conferimento può comportare
        l’impossibilità di ottenere quanto richiesto.
        <br />
        <br />
        DATI FORNITI VOLONTARIAMENTE DALL’UTENTE
        <br />
        L'invio facoltativo, esplicito e volontario di posta elettronica agli
        indirizzi indicati su questo sito comporta la successiva acquisizione
        dell’indirizzo del mittente, necessario per rispondere alle richieste,
        nonché degli eventuali altri dati personali inseriti nella missiva che,
        salvo diverse esigenze debitamente comunicate, verranno conservati per
        il tempo necessario a soddisfare le richieste. Di seguito sono rese
        disponibili specifiche informative riferite alle pagine del sito
        predisposte per particolari servizi a richiesta o tramite le quali sia
        possibile acquisire ulteriori dati personali.
        <br />
        <br />
        TRATTAMENTI CONSEGUENTI AD UNA RICHIESTA DI CONTATTO
        <br />
        I dati personali conferiti spontaneamente dell'interessato tramite
        l'area contatti :
        <br />
        sono trattati con strumenti prevalentemente automatizzati per:
        <br />
        assicurare una risposta certa e tempestiva e soddisfare le richieste
        dell'interessato stesso, adempiere ad obblighi derivanti da leggi, norme
        e regolamenti comunitari; adempimento disposizioni impartite
        dall’Autorità Giudiziaria, alimentare il sistema di acquisizione delle
        conoscenze del pubblico, necessarie alla verifica, al miglioramento e
        quindi alla progettazione di un servizio sempre più adeguato alla
        domanda, costituendo tale finalità un legittimo interesse del Titolare
        ed andando essa a vantaggio dell’utenza,
        <br />
        i recapiti, gli indirizzi postali e di posta elettronica forniti
        potranno essere utilizzati per l’invio di comunicazioni di cortesia e/o
        di materiale informativo/offerte relative ai prodotti e servizi
        dell’Associazione FINCREA ed iniziative commerciali o eventi
        promossi dal Titolare. Resta inteso che l'interessato ha facoltà di
        opporsi in ogni momento al presente trattamento
        <br />
        <br />
        2. potranno essere trattati da addetti alla comunicazione e marketing,
        personale addetto alla manutenzione dei sistemi informatici che ha il
        compito di garantire la funzionalità dei sistemi, la sicurezza dei dati
        e le operazioni di backup, altro personale dipendente nei limiti degli
        incarichi ricevuti e di quanto previsto dalle procedure aziendali ed
        altri soggetti che forniscono servizi per finalità ausiliare al
        soddisfacimento delle richieste dell'interessato, nei limiti
        strettamente necessari per svolgere i propri compiti
        <br />
        <br />
        3. potranno essere comunicati o resi disponibili:
        <br />
        <br />
        a soggetti che possono accedere ai dati in forza di disposizione di
        legge, di regolamento o di normativa comunitaria, nei limiti previsti da
        tali norme, ad altri soggetti che forniscono servizi per finalità
        connesse al soddisfacimento delle richieste dell'interessato, nei limiti
        strettamente necessari per svolgere i propri compiti; a società
        collegate (controllate - controllanti - appartenenti all’Associazione
        FINCREA) sempre per correnti finalità amministrativo contabili
        connesse al soddisfacimento delle richieste dell'interessato;
        <br />
        <br />
        Nella compilazione dei form vengono indicati i campi la cui compilazione
        è facoltativa, in assenza degli altri dati richiesti non sarà possibile
        soddisfare le richieste dell’interessato. Qualora all'atto della
        richiesta di contatto l'interessato dovesse comunicare categorie
        particolari di dati (quali: dati personali che rivelino l'origine
        razziale o etnica, le opinioni politiche, le convinzioni religiose o
        filosofiche, o l'appartenenza sindacale, nonché trattare dati genetici,
        dati biometrici intesi a identificare in modo univoco una persona
        fisica, dati relativi alla salute o alla vita sessuale o
        all'orientamento sessuale della persona) potrà essere richiesto uno
        specifico consenso al loro trattamento, in assenza del quale potrebbe
        risultare impossibile dare corso alle richieste dell'interessato stesso,
        perciò SI INVITA L’UTENTE A NON INSERIRE NELLA PRIMA RICHIESTA DI
        CONTATTO INFORMAZIONI RIENTRANTI IN QUESTE CATEGORIE.
        <br />
        <br />
        CONSERVAZIONE DEI DATI <br />
        I dati comunicati, salvo diverse indicazioni dell’interessato, verranno
        conservati per il tempo necessario a soddisfare le richieste
        dell’interessato stesso ed ottemperare a norme di legge. Analogamente
        ove l’interessato avesse avesse un rapporto di diverso tipo con il
        Titolare (rapporto contrattuale, acquisto di servizio, etc.) i dati
        verranno conservati, se ad esso pertinenti, per la durata del rapporto
        stesso, cessato il quale la conservazione verrà proseguita solo se
        prevista da norme di legge e in conformità alle norme sulla
        conservazione della documentazione amministrativa. I recapiti per cui
        sia stato conferito il consenso per l’invio di comunicazioni di
        carattere commerciale saranno conservati sino a 12 mesi successivi
        all’ultimo invio o sino alla revoca del consenso da parte
        dell’interessato
        <br />
        <br />
        ISCRIZIONE ALLA NEWSLETTER <br />
        I dati personali conferiti spontaneamente dell'interessato iscrivendosi
        alla Newsletter:
        <br />
        <br />
        sono trattati con strumenti prevalentemente automatizzati al solo scopo
        di soddisfare la richiesta dell'interessato stesso (ricevere la
        newsletter e aggiornamenti su servizi/prodotti offerti da FINCREA),
        che ha facoltà di interrompere in qualsiasi momento il suddetto
        trattamento; potranno essere trattati da addetti alla comunicazione e
        marketing, personale addetto alla manutenzione dei sistemi informatici
        che ha il compito di garantire la funzionalità dei sistemi, la sicurezza
        dei dati e le operazioni di backup, altro personale dipendente nei
        limiti degli incarichi ricevuti e di quanto previsto dalle procedure
        aziendali ed altri soggetti che forniscono servizi per finalità
        ausiliare al soddisfacimento delle richieste dell'interessato, nei
        limiti strettamente necessari per svolgere i propri compiti; potranno
        essere comunicati o resi disponibili: a soggetti che possono accedere ai
        dati in forza di disposizione di legge, di regolamento o di normativa
        comunitaria, nei limiti previsti da tali norme; ad altri soggetti che
        forniscono servizi per finalità connesse al soddisfacimento delle
        richieste dell'interessato, nei limiti strettamente necessari per
        svolgere i propri compiti; a società collegate (controllate -
        controllanti) sempre per correnti finalità amministrativo contabili
        connesse al soddisfacimento delle richieste dell'interessato;
        <br />
        <br />
        COOKIE - Cosa sono i Cookie <br />
        Un cookie è una piccola quantità di dati, spesso contenenti un codice
        identificativo unico anonimo, che vengono inviati al browser da un
        server Web e che vengono successivamente memorizzati sul disco fisso del
        computer dell'utente. Il cookie viene poi riletto e riconosciuto solo
        dal sito Web che lo ha inviato ogniqualvolta si effettua una connessione
        successiva. Ricordiamo che il browser è il software che permette di
        navigare nella Rete tramite la visualizzazione ed il trasferimento delle
        informazioni sul disco fisso del computer dell'utente. Se le preferenze
        del browser sono impostate in modo da accettare i cookies, qualsiasi
        sito Web può inviare i suoi cookies al browser, ma – al fine di
        proteggere la privacy – può rilevare solo ed esclusivamente quelli
        inviati dal sito stesso, e non quelli invece inviati al browser da altri
        siti.
        <br />
        <br />
        In ogni caso i cookie non possono causare danni al computer dell'utente.
        <br />
        <br />
        ALTERNATIVE PER L'IMPOSTAZIONE DEI COOKIES <br />
        La privacy dell'utente è garantita essenzialmente dal fatto che egli può
        IN QUALSIASI MOMENTO:
        <br />
        <br />
        configurare il browser in modo da accettare tutti i cookie, rifiutarli
        tutti oppure ricevere una nota di avviso nel momento in cui ne viene
        inviato uno, cancellare uno, alcuni o tutti i cookie.
        <br />
        <br />
        Ogni browser ha le sue impostazioni specifiche, quindi si ricorda di
        consultare la sezione di "Aiuto" del browser utilizzato per avere
        maggiori informazioni su come modificarne le preferenze.
        <img src={tab1} alt="elenco cookie" className="col p-0" />
        <br />
        <br />
        I COOKIES USATI SU QUESTO SITO
        <br />
        Non viene fatto uso di cookie per la trasmissione di informazioni di
        carattere personale, ovvero sistemi per il tracciamento degli utenti.
        L’uso di c.d. cookie di sessione (che non vengono memorizzati in modo
        persistente sul computer dell’utente e svaniscono con la chiusura del
        browser) è strettamente limitato alla trasmissione di identificativi di
        sessione necessari per consentire l’esplorazione sicura ed efficiente
        del sito.
        <br />
        <br />
        In ogni caso i cookie non possono causare danni al dispositivo
        utilizzato dall'utente,
        <br />
        <br />
        Di seguito elenchiamo i cookie utilizzati su questo sito indicando a
        quale categoria appartenga ciascuno di essi SECONDO LA SEGUENTE
        CLASSIFICAZIONE
        <br />
        <br />
        <img src={tab2} alt="elenco cookie" className="col p-0" />
        <br />
        <br />
        ELENCO COOKIE
        <br />
        <br />
        Nome Cookie: has_js; CATEGORIA: A; PERMANENZA sul terminale: Fino a
        Termine Sessione; A Cosa Serve: Identificativo di Sessione; PROPRIETARIO
        (Link al sito terzo): //.
        <br />
        <br />
        DIRITTI DEGLI INTERESSATI
        <br />
        <br />
        L’interessato ha il diritto:
        <br />
        <br />
        di chiedere al titolare del trattamento l'accesso ai dati personali e la
        rettifica o la cancellazione degli stessi o la limitazione del
        trattamento dei dati personali che lo riguardano e di opporsi al loro
        trattamento, se il trattamento è effettuato con mezzi automatizzati
        (informatici) e sulla base del proprio consenso, di ricevere in un
        formato strutturato, di uso comune e leggibile da dispositivo automatico
        i dati personali che lo riguardano e/o di ottenerne la trasmissione
        diretta ad altro titolare del trattamento, se tecnicamente fattibile di
        revocare il proprio consenso in qualsiasi momento (senza che sia
        pregiudicata la liceità del trattamento basata sul consenso prima della
        revoca), ovviamente ciò per i trattamenti effettuati sulla base di tale
        presupposto di proporre reclamo a un'autorità di controllo: Garante per
        la protezione dei dati personali - Piazza di Monte Citorio n. 121 00186
        ROMA - Fax: (+39) 06.69677.3785 - Centralino telefonico: (+39) 06.696771
        - E-mail: garante@gpdp.it - Posta Certificata: protocollo@pec.gpdp.it.
        Le richieste vanno rivolte a FINCREA tramite l’indirizzo
        info@fincrea.it
        <br />
        <br />
        AGGIORNAMENTI A QUESTA INFORMATIVA
        <br />
        <br />
        La presente Informativa potrà essere aggiornata o modificata; in tal
        caso, sulla nuova versione, verranno evidenziate le modifiche
        effettuate.
      </div>
    </div>
  );
};

export default Privacy;
