import React, { useState } from "react";
import { Header, Image, Button, Icon } from "semantic-ui-react";
import logo from "../images/logoFincrea.png";

import "moment/locale/it";

const Landingpage = () => {
  return (
    <React.Fragment>
      <div className="text-center">
        <Image centered src={logo} size="medium" />
        <Header as="h4" className="mt-2" color="grey">
          <p>
            Viale della Regione Siciliana
            <br />
            Nord Ovest, 3414 - Palermo
            <br />
            091 6090106
            <br />
            info@fincrea.it
          </p>
        </Header>
      </div>
      <div className="text-center mt-3">
        <Header as="h6">
          <p>
            <small>
              Fincrea di Mangiaracina Nicolò, n° OAM-A12164, P.IVA 04975110828
              CF: MNGNCL70P05G273D,
              <br />
              legato da un rapporto contrattuale di monomandato con Dynamica
              Ratail S.p.A.
              <br />
              (intern. iscr. all'Albo degli intermediari finanziari ex art. 106
              TUB, al n. 181) a cui il Consumatore
              <br />
              può discrezionalmente rivolgersi per scolgere attività di
              istruttoria del prestito, per la definizione
              <br />
              dei relativi rapporti contabili ed ogni altra attivà afferente
              esclusivamente la conclusione dell'affare.
            </small>
          </p>
        </Header>
        <Button icon labelPosition="right" primary onClick={() => window.open("https://www.dynamicaretail.it")}>  
          Informazioni <Icon name="info" />
        </Button>
        <Button icon labelPosition="right" primary onClick={() => window.open("https://www.dynamicaretail.it/trasparenza/", "_blank")}>
          Trasparenza <Icon name="magnify" />
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Landingpage;
